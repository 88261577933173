
import Vue from 'vue';

import App from '~/app/App.vue';
import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
import TheFooterContainer from '~/containers/TheFooterContainer.amp';
import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
import GoogleOneTapAuth from '~/components/GoogleOneTapAuth/GoogleOneTapAuth.vue';

export default Vue.extend({
  name: 'SimpleLayout',
  components: {
    App,
    TopHeaderContainer,
    TheFooterContainer,
    NavMenuContainer,
    GoogleOneTapAuth,
  },
  data: () => ({
    searchString: '' as string | (string | null)[],
  }),
});
