var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DefaultLayout',{staticClass:"health-template",class:_vm.computedClass,style:(`
    --preview-header-color: #0530a4;
    --main-color: #2962f9;
    --main-color-2: #2962F9;
    --hover-color: #264eb1;
    --hover-color-2: #264eb1;
    --bg-color-substrate: #f8f9fb;
    --bg-color-substrate-2: #E1EAFD;
    --bg-color-substrate-3: #F3F5FC;
  `)},[(_vm.isHealthHome)?_c('TheAboutUs',{attrs:{"slot":"above-content"},slot:"above-content"}):_vm._e(),_vm._v(" "),_c('nuxt')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }