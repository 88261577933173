
import Vue from 'vue';

import App from '~/app/App.vue';
import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
import TheFooterContainer from '~/containers/TheFooterContainer.amp';
import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
import PrimaryMenu from '~/components/PrimaryMenu.vue';
import NavMenuBelowHeaderContainer from '~/containers/NavMenuBelowHeaderContainer';
import type { Menus } from '~/store/menus';
import { UIState } from '~/store/ui';
import WindowBreakpoints from '~/utils/windowBreakpoints';
import ModalDeleteAccount from '~/components/ModalDeleteAccount/ModalDeleteAccount.vue';

import GoogleOneTapAuth from '~/components/GoogleOneTapAuth/GoogleOneTapAuth.vue';
import {
  HIDE_HEADER,
  SHOW_HEADER,
  HIDE_PRIMARY_MENU,
  SHOW_PRIMARY_MENU,
  HIDE_MODAL_DELETE_ACCOUNT,
  DELETE_USER_ACCOUNT,
  LOGOUT,
} from '@/constants';
import { leavePrivateChannel, pushBadge, pusherResetStore } from '~/components/Pusher/websockets-functions';

export default Vue.extend({
  name: 'ProfileLayout',
  components: {
    App,
    TopHeaderContainer,
    TheFooterContainer,
    NavMenuContainer,
    PrimaryMenu,
    NavMenuBelowHeaderContainer,
    ModalDeleteAccount,
    GoogleOneTapAuth,
  },

  data: () => ({
    breakpoints: null as null | WindowBreakpoints,
    breakpointHeadersExclude: ['mobile', 'tablet'],
    namesHeadersExclude: ['profile-settings', 'profile-edit', 'profile-favorites-edit'],
  }),

  computed: {
    isShowModalDelete (): boolean {
      return (this.$store.state.ui as UIState)?.isModalDeleteAccountShow;
    },
    primaryNavMenuItems (): Array<MenuItem> {
      return (this.$store.getters.menus as Menus)?.projects?.items ?? [];
    },
    isHeadShow (): boolean {
      return (this.$store.state.ui as UIState)?.isHeaderShow;
    },
    isSidebarShow (): boolean {
      return this.isShowHeadByRoute && this.isShowHeadByBreakpoint;
    },
    isPrimaryMenuShow (): boolean {
      return (this.$store.state.ui as UIState)?.isPrimaryMenuShow;
    },
    breakpointCurrent (): any {
      return this.breakpoints ? this.breakpoints.breakpointCurrent : null;
    },
    isShowHeadByBreakpoint (): boolean {
      return !this.breakpointHeadersExclude.includes(this.breakpointCurrent);
    },
    isShowHeadByRoute (): boolean {
      return !this.namesHeadersExclude.includes(this.routeName);
    },
    routeName (): string {
      return this.$route.name ?? '';
    },
    backToProfileTitle (): string {
      const titlesByRouteName = {
        'profile-edit': 'Редактировать профиль',
        'profile-settings': 'Настройки',
        'profile-favorites-edit': 'Управление папками',
      } as { [key: string]: string };
      return titlesByRouteName[this.routeName] || '';
    },
  },

  watch: {
    breakpointCurrent () {
      this.checkHeadShow();
    },

    $route () {
      this.checkHeadShow();
    },
  },

  mounted () {
    this.breakpoints = new WindowBreakpoints();
  },

  destroyed () {
    if (this.breakpoints) {
      this.breakpoints.destroy();
    }
  },

  methods: {
    backToProfile (): void {
      const { href } = this.$router.resolve({
        name: 'profile-comments',
        params: this.$route.params,
        query: this.$route.query,
      });
      location.href = href;
    },
    checkHeadShow (): void {
      if (this.isShowHeadByRoute || this.isShowHeadByBreakpoint) {
        this.$store.commit(SHOW_HEADER);
        this.$store.commit(SHOW_PRIMARY_MENU);
      } else {
        this.$store.commit(HIDE_HEADER);
        this.$store.commit(HIDE_PRIMARY_MENU);
      }
    },

    onCloseModal (): void {
      this.$store.commit(HIDE_MODAL_DELETE_ACCOUNT);
    },

    onDeleteAccount (reason: any): void {
      this.$store.dispatch(DELETE_USER_ACCOUNT, reason)
        .then((response) => {
          if (response.data.success) {
            this.logout();
          } else {
            throw new Error('Ошибка при удалении аккаунта');
          }
        })
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.error(error);
          pushBadge(this.$store.dispatch, {
            content: 'Произошла ошибка при удалении аккаунта, попробуйте позже',
            isError: true,
          });
        })
        .finally(() => {
          this.$store.commit(HIDE_MODAL_DELETE_ACCOUNT);
        });
    },

    logout () {
      const { href } = this.$router.resolve({
        path: '/',
        query: this.$route.query,
      });
      location.href = href;

      leavePrivateChannel();
      pusherResetStore(this.$store);

      this.$store.dispatch(LOGOUT);
    },
  },
});
