
import Vue from 'vue';
import { getImagesBySizes } from '@devhacker/shared/utils/image/getImagesBySizes';
import TheHeader from '~/components/TheHeader.amp/TheHeader.amp.vue';
import TheSidebarContainer from '~/containers/TheSidebarContainer.amp/TheSidebarContainer.amp.vue';
import TheFooterContainer from '~/containers/TheFooterContainer.amp';
import { ARTICLE_IMAGE_SIZES } from '~/constants/imageSizes';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import { isAnyRecipePage, isHealthPath } from '~/router';
import { normalizeRecipe } from '~/libs/normalizeRecipe';

export default Vue.extend({
  name: 'DefaultAmpLayout',

  components: {
    TheHeader,
    TheSidebarContainer,
    TheFooterContainer,
  },

  data () {
    return {
      nextPages: [],
    };
  },

  async fetch () {
    const isRecipePage = isAnyRecipePage(this.$route.name);

    const responseItems = await this.fetchArticlesAmpNextPage(isRecipePage);

    const pageSlug = getSlugFromUrl(this.$route.fullPath);

    if (isRecipePage) {
      this.nextPages = responseItems.reduce(
        (acc: Array<{ title: string; url: string; image: string }>, article: Recipe) => {
          const slugArticle = `${getSlugFromUrl(article.url)}amp/`;

          if (pageSlug !== slugArticle) {
            acc.push({
              title: article.title,
              url: `${article.url}amp/`,
              image: this.getImage(article.images, []),
            });
          }
          return acc;
        },
        [],
      );
    } else {
      this.nextPages = responseItems.reduce(
        (acc: Array<{ title: string; url: string; image: string }>, article: IArticle) => {
          const slugArticle = `${getSlugFromUrl(article.url)}amp/`;

          if (!article.adsToken && pageSlug !== slugArticle && !article.teaserUrl) {
            acc.push({
              title: article.title,
              url: `${article.url}amp/`,
              image: this.getImage(article.defaultImg, article.img),
            });
          }
          return acc;
        },
        [],
      );
    }
  },

  computed: {
    isHealthPath (): boolean {
      return isHealthPath(this.$route.path);
    },
  },

  methods: {
    async fetchArticlesAmpNextPage (isRecipePage: boolean) {
      if (isRecipePage) {
        const responseData = await this.$wordpressApi.fetchRecipes(1, '/recipes/', 40);
        return responseData?.recipes.map(normalizeRecipe);
      } else {
        const response = await this.$wordpressApi.fetchArticles(this.$route, 1, 40);
        return response.data.items;
      }
    },
    getImage (defaultImg: IArticleImg[], img: IArticleImg[] | []): string {
      const cover = getImagesBySizes([ARTICLE_IMAGE_SIZES.L.width], img);
      if (cover.length) {
        return cover[0].url;
      }

      const defaultCover = getImagesBySizes([ARTICLE_IMAGE_SIZES.L.width], defaultImg);
      if (defaultCover.length) {
        return defaultCover[0].url;
      }

      return '';
    },
  },
});
